const lazyFunctions = {
	destroyVideo: function() {
		let videos = [].slice.call(document.querySelectorAll("video"));
		videos.forEach(video => {
			video.pause();
			let sources = [].slice.call(video.children);
			sources.forEach(source => {
				source.removeAttribute("src")
			})
			video.load();
		});
	},
	videoLazy: function() {
		let lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));
		if ("IntersectionObserver" in window) {
			let lazyVideoObserver = new IntersectionObserver(function(entries) {
				entries.forEach(function(video) {
					if (video.isIntersecting) {
						for (let source in video.target.children) {
							let videoSource = video.target.children[source];
							if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
								videoSource.src = videoSource.dataset.src;
							}
						}

						video.target.load();
						video.target.classList.remove("lazy");
						lazyVideoObserver.unobserve(video.target);
					}
				});
			});

			lazyVideos.forEach(function(lazyVideo) {
				lazyVideoObserver.observe(lazyVideo);
			});
		}
	},
	imageLazy: function() {
		let lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
		if ("IntersectionObserver" in window) {
			let lazyImageObserver = new IntersectionObserver(function(entries) {
				entries.forEach(function(entry) {
					if (entry.isIntersecting) {
						let lazyImage = entry.target;
						if(lazyImage.dataset.src !== undefined && lazyImage.dataset.srcset !== undefined) {
							lazyImage.src = lazyImage.dataset.src;
							lazyImage.srcset = lazyImage.dataset.srcset;
							lazyImage.classList.remove("lazy");
							lazyImageObserver.unobserve(lazyImage);
						}					
					}
				});
			});

			lazyImages.forEach(function(lazyImage) {
				lazyImageObserver.observe(lazyImage);
			});
		}
	},
}

export default lazyFunctions